import { FC } from "react";
import { FontBold } from "../../../../../../TrueUI";
import style from "./BillDetails.module.css";
import { BillDetailsWrapperProps } from "./TypesPaymentBillDetails";
import FormDiagnosis from "./DiagnosisComponents/FormDiagnosis";
import FormBillIndividualDetails from "./BillIndividualDetails/FormBillIndividualDetails";

const BillDetailsWrapper: FC<BillDetailsWrapperProps> = ({}) => {
  return (
    <div className={style.billing_details_main_container}>
      <div>
        <div className={style.billing_details_wrapper_row}>
          <FontBold fontType={"BOLD_TITLE"}>DIAGNOSIS</FontBold>
          <FormDiagnosis />
        </div>

        <div className={style.billing_details_wrapper_row}>
          <FontBold fontType={"BOLD_TITLE"}>
            PROCEDURES, SERVICES, OR SUPPLIES
          </FontBold>
          <FormBillIndividualDetails />
        </div>
      </div>
    </div>
  );
};

export default BillDetailsWrapper;
