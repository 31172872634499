import { FC, useEffect, useState } from "react";
import {
  Col,
  Input,
  InputDate,
  Row,
  Select,
} from "../../../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../../../TrueUI/Grids/Row";
import { IconButton } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { PaymentEOBDetailDto } from "../../../../../../../../dtos/payment-eob-detail-dto";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../../../../../utilities/inputPropsConfigurationFunctions";

const FormBillIndividualDetails: FC = () => {
  const [billIndividualDetails, setBillIndividualDetails] =
    useState<Partial<PaymentEOBDetailDto> | null>(null);

  const [payAmount, setPayAmount] = useState(
    (billIndividualDetails?.amountAllowed ?? 0) *
      (billIndividualDetails?.quantity ?? 0)
  );

  useEffect(() => {
    setPayAmount(
      (billIndividualDetails?.amountAllowed ?? 0) *
        (billIndividualDetails?.quantity ?? 0)
    );
  }, [billIndividualDetails?.amountAllowed, billIndividualDetails?.quantity]);

  return (
    <Row
      {...rowWithNoMarginNorGutter}
      numberOfColumns={24}
      verticalAlign={"flex-end"}
    >
      <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
        <InputDate
          tabIndex={1}
          id="id-service-from-date"
          name={"bill-individual-detail-service-from-date"}
          label="Service From"
          value={billIndividualDetails?.serviceFromDate ?? new Date()}
          onChangeRawValue={(value) =>
            setBillIndividualDetails({
              ...billIndividualDetails,
              serviceFromDate: value,
            })
          }
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
        <InputDate
          tabIndex={2}
          id="id-service-to-date"
          name={"bill-individual-detail-service-to-date"}
          label="Service To"
          value={
            billIndividualDetails?.serviceToDate ??
            billIndividualDetails?.serviceFromDate ??
            new Date()
          }
          onChangeRawValue={(value) =>
            setBillIndividualDetails({
              ...billIndividualDetails,
              serviceToDate: value,
            })
          }
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
        <Input
          tabIndex={3}
          id="bill-individual-detail-cpt-hcpcs"
          name="bill-individual-detail-cpt-hcpcs"
          maxLength={7}
          label="CPT/HCPCS"
          value={billIndividualDetails?.serviceCode ?? ""}
          onChangeRawValue={(value) =>
            setBillIndividualDetails({
              ...billIndividualDetails,
              serviceCode: value,
            })
          }
          helperText="(Placeholder for description)"
        />
      </Col>
      <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}>
        <Select
          tabIndex={4}
          {...selectConfiguration(
            "bill-individual-detail-diagnosis-code",
            "bankAccountId",
            "Diag"
          )}
          options={[
            { displayName: "A", intValue: 1 },
            { displayName: "B", intValue: 2 },
            { displayName: "C", intValue: 3 },
            { displayName: "D", intValue: 4 },
          ]}
          value={billIndividualDetails?.diagnosisCodeId}
          onChange={(value) =>
            setBillIndividualDetails({
              ...billIndividualDetails,
              diagnosisCodeId: value,
            })
          }
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
        <Input
          tabIndex={5}
          {...inputConfiguration(
            "bill-individual-detail-charges",
            "name-charges",
            "Charges"
          )}
          maxNumericValue={999999999}
          type={"fixedCurrency"}
          prefix={""}
          align={"right"}
          value={billIndividualDetails?.amountBilled}
          labelFontType={"BOLD_CAPTION"}
          onChangeRawValue={(value) =>
            setBillIndividualDetails({
              ...billIndividualDetails,
              amountBilled: value,
            })
          }
        />
      </Col>
      <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
        <Input
          tabIndex={6}
          {...inputConfiguration(
            "bill-individual-detail-quantity",
            "name-quantity",
            "Qty"
          )}
          maxNumericValue={999999999}
          type={"number"}
          prefix={""}
          align={"right"}
          value={billIndividualDetails?.quantity}
          labelFontType={"BOLD_CAPTION"}
          displayNumericArrows={true}
          onChangeRawValue={(value) =>
            setBillIndividualDetails({
              ...billIndividualDetails,
              quantity: value,
            })
          }
        />
      </Col>
      <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
        <Input
          tabIndex={7}
          {...inputConfiguration(
            "bill-individual-detail-allowed-amount",
            "name-allowed-amount",
            "Allowed"
          )}
          maxNumericValue={999999999}
          type={"fixedCurrency"}
          prefix={""}
          align={"right"}
          value={billIndividualDetails?.amountAllowed}
          labelFontType={"BOLD_CAPTION"}
          readOnly
        />
      </Col>
      <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 3 }}>
        <Input
          tabIndex={8}
          {...inputConfiguration(
            "bill-individual-detail-pay-amount",
            "name-pay-amount",
            "Pay Amount"
          )}
          maxNumericValue={999999999}
          type={"fixedCurrency"}
          prefix={""}
          align={"right"}
          value={payAmount}
          labelFontType={"BOLD_CAPTION"}
          readOnly
        />
      </Col>
      <Col breakpoints={{ xs: 2, sm: 2, md: 2, lg: 2, xl: 2 }}>
        <Input
          tabIndex={9}
          id="bill-individual-detail-eob"
          name="bill-individual-detail-eob"
          label="EOB"
          readOnly
          value={billIndividualDetails?.eOBCode ?? "00"}
        />
      </Col>
      <Col
        breakpoints={{ xs: 2, sm: 2, md: 2, lg: 1, xl: 1 }}
        verticalAlignSelf={"center"}
      >
        <IconButton
          id={`plus-bill-individual-detail-action`}
          className="plus-minus-icon-container"
          sx={{ padding: "5px" }}
          disabled={false}
          onClick={() => console.log("Save details")}
          true-element={"option-plus-icon"}
          color={"default"}
        >
          <AddOutlined className="plus-action-button" fontSize="small" />
        </IconButton>
      </Col>
    </Row>
  );
};

export default FormBillIndividualDetails;
