import { FC, useEffect, useState } from "react";
import { StatusEnums } from "../../../../dtos/status-enums";
import { useApiGet } from "../../../../hooks";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { Button, Col, Collapse, Row, SplitButton } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import PolicyQuoteBottomButtons from "../PolicyQuoteForm/PolicyQuoteBottomButtons";
import {
  PolicyStateCollectionUIProps,
  PolicyQuoteInsuredAndActiveSetterProps,
  PolicyStateInModalStateUIProps,
  EXPOSURE_PREMIUM_SECTION,
  PolicyStateInModalStateAnyUIProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getPolicyQuoteStates,
  getStateByStateCodeAndDates,
} from "../PolicyQuoteForm/PolicyQuoteUtils";
import {
  updatePolicyQuoteInformation,
  updateQuoteInPolicyQuote,
  updateQuoteInPolicyQuoteMultipleTargets,
} from "../updatesPolicyQuoteFunctions";
import ExposurePremiumFooter from "./ExposurePremiumFooter";
import {
  getDefaultStateWithDates,
  getInitialValueForStateCollection,
  getNewStatesByModalStatesObject,
  getStatesUpdatedAsCollapsed,
  getStatesUpdatedByExpanded,
  getAllStatesCollapsedOrExpanded,
  exposuresUpdated,
  statesUpdatedWithExposures,
} from "../../Policy/PolicyQuoteExposurePremium/ExposurePremiumUtils";
import ModalState from "../../Policy/PolicyQuoteExposurePremium/ModalState";
import ExposurePremiumStateHeader from "./ExposurePremiumStateHeader";
import {
  EXPOSURES_CHANGED_NAME,
  usePolicyQuotePremiumRowTriggerComponent,
  usePolicyQuoteTriggerComponent,
} from "../hooks/usePolicyQuoteTriggerComponent";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import { ProgramStateDto } from "../../../../dtos/program-state-dto";
import { Box } from "@mui/material";
import { collapseContainerStyles } from "./ExposurePremiumStyles";
import { castStringToTags } from "../../../../utilities/stringFunctions";
import {
  getDateObject,
  getValidatedDateToPost,
  isDateEqualDate,
} from "../../../../utilities/dateFunctions";
import { validatePreviousSections } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import ModalStateAny from "./ModalStateAny";
import {
  getStateListUpdatedByExposures,
  getStatesUpdatedByExposures,
} from "../AuditWorksheet/AuditWorksheetUtils";
import PolicyCalculator from "../PolicyCalculator/PolicyCalculator";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";

const PROGRAM_STATE_API_PATH =
  "api/ProgramState/GetProgramStateByEffectiveDateAndStatus";

const ExposurePremiumMain: FC<PolicyQuoteInsuredAndActiveSetterProps> = ({
  insuredId,
  tabKey,
  setActiveSection,
  readOnly = false,
  quoteStatus,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;

  const [allStatesExpanded, setAllStatesExpanded] = useState(true);
  const [readyToRender, setReadyToRender] = useState<boolean>(false);
  const [stateCollectionUI, setStateCollectionUI] =
    useState<PolicyStateCollectionUIProps>(getInitialValueForStateCollection);

  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const { setPolicyQuoteTriggers } = usePolicyQuoteTriggerComponent();
  const { setPolicyQuotePremiumRowTriggers } =
    usePolicyQuotePremiumRowTriggerComponent();
  const effectiveDate = getDateObject(
    getAtom()?.policyQuoteInformation?.policyQuote?.effectiveDate
  );
  const expirationDate = getDateObject(
    getAtom()?.policyQuoteInformation?.policyQuote?.expirationDate
  );

  const getUrlString = () => {
    const atomValue = getAtom();

    return `${PROGRAM_STATE_API_PATH}?effectiveDate=${getValidatedDateToPost(
      atomValue?.policyQuoteInformation?.policyQuote?.effectiveDate ??
        new Date()
    )}&status=${StatusEnums.ACTIVE}`;
  };

  const { responseGet, dispatchGet } = useApiGet<ProgramStateDto[]>(
    getUrlString()
  );

  const splitButtonOptions = [
    {
      option: "ADD STATE",
      action: () =>
        setStateCollectionUI({
          ...stateCollectionUI,
          isModalOpen: true,
        }),
    },
    {
      option: "ADD IF ANY STATE",
      action: () =>
        setStateCollectionUI({
          ...stateCollectionUI,
          isModalAnyOpen: true,
        }),
    },
  ];

  useEffect(() => {
    const atomValue = getAtom();
    const activeSection =
      atomValue?.policyQuoteInformation?.activeSection ??
      QuotePolicySectionEnum.INFORMATION;
    const isValid = validatePreviousSections(atomValue, activeSection);

    if (isValid) dispatchGet();
    else {
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "activeSection",
        QuotePolicySectionEnum.INFORMATION
      );
      setAtom(newAtomValue);
      setActiveSection?.(QuotePolicySectionEnum.INFORMATION);
    }
  }, []);

  const setInitialValuesBasedOnAtomValues = () => {
    const atomValue = getAtom();

    const currentStates = getPolicyQuoteStates(
      atomValue?.policyQuoteInformation
    );

    const currentStateCodeList =
      atomValue?.policyQuoteInformation?.policyQuote?.quote?.stateList;

    const initialStateValueWhenOpen = getDefaultStateWithDates(
      atomValue?.policyQuoteInformation?.policyQuote?.configuration
        ?.quoteStateActionTypeList ?? [],
      responseGet?.responseData?.[0].selectOption,
      atomValue?.policyQuoteInformation?.policyQuote?.effectiveDate,
      atomValue?.policyQuoteInformation?.policyQuote?.expirationDate
    );

    if (currentStates === undefined || currentStates === null) {
      setStateCollectionUI({
        ...stateCollectionUI,
        allStates:
          responseGet?.responseData?.map((option) => option.selectOption) ?? [],
        allProgramStates: responseGet?.responseData ?? [],
        initialStateValueWhenOpen: initialStateValueWhenOpen,
      });
    } else {
      currentStates.every((state) => state.expanded === true)
        ? setAllStatesExpanded(true)
        : setAllStatesExpanded(false);

      setStateCollectionUI({
        ...stateCollectionUI,
        allStates:
          responseGet?.responseData?.map((option) => option.selectOption) ?? [],
        allProgramStates: responseGet?.responseData ?? [],
        initialStateValueWhenOpen: initialStateValueWhenOpen,
        stateCodeList: currentStateCodeList ?? "",
        states: currentStates,
      });
    }

    setReadyToRender(true);
  };

  useEffect(() => {
    if (responseGet?.responseData) {
      setInitialValuesBasedOnAtomValues();
    }
  }, [responseGet]);

  const saveNewState = (
    newStatesFromModal: PolicyStateInModalStateUIProps[],
    stateCodeList: string
  ) => {
    const atomValue = getAtom();

    const newStates = getNewStatesByModalStatesObject(
      atomValue,
      newStatesFromModal
    );

    const newAtomValue = updateQuoteInPolicyQuoteMultipleTargets(
      atomValue,
      ["stateList", "states"],
      [stateCodeList, newStates]
    );

    setAtom(newAtomValue);
    setStateCollectionUI({
      ...stateCollectionUI,
      states: newStatesFromModal,
      stateCodeList: stateCodeList,
      isModalOpen: false,
    });
  };

  const deleteState = (
    stateCode: string,
    effectiveDate: Date,
    expirationDate: Date
  ) => {
    const atomValue = getAtom();
    const currentStates = getPolicyQuoteStates(
      atomValue?.policyQuoteInformation
    );

    const stateToDelete = getStateByStateCodeAndDates(
      stateCode,
      effectiveDate,
      expirationDate,
      atomValue
    );

    const deletedQuoteIdList =
      atomValue?.policyQuoteInformation?.policyQuote?.quote
        ?.deletedQuoteStateIdList ?? [];

    const deletedQuoteIdListUpdated =
      stateToDelete?.quoteStateID !== null &&
      stateToDelete?.quoteStateID !== undefined &&
      stateToDelete.quoteStateID !== 0
        ? [...deletedQuoteIdList, stateToDelete?.quoteStateID]
        : deletedQuoteIdList;

    const statesWithoutDeletedState = currentStates.filter(
      (state) =>
        state.stateCode !== stateCode ||
        !isDateEqualDate(state.effectiveDate, effectiveDate) ||
        !isDateEqualDate(state.expirationDate, expirationDate)
    );

    const stateListUpdated = statesWithoutDeletedState
      .map((state) => state.stateCode ?? "")
      .join(",");

    const newAtomValue = updateQuoteInPolicyQuoteMultipleTargets(
      atomValue,
      ["deletedQuoteStateIdList", "stateList", "states"],
      [deletedQuoteIdListUpdated, stateListUpdated, statesWithoutDeletedState]
    );

    setAtom(newAtomValue);
    setStateCollectionUI({
      ...stateCollectionUI,
      states: statesWithoutDeletedState,
      stateCodeList: stateListUpdated,
    });

    const firstState =
      statesWithoutDeletedState.length > 0
        ? statesWithoutDeletedState[0]
        : null;
    const exposureTableAtomKey = `${insuredId}_${EXPOSURES_CHANGED_NAME}_${
      firstState?.stateCode
    }_${getValidatedDateToPost(
      firstState?.effectiveDate
    )}_${getValidatedDateToPost(firstState?.expirationDate)}`;
    setPolicyQuotePremiumRowTriggers([exposureTableAtomKey]);
  };

  const saveNewStatesAny = (
    localModalStateUI?: PolicyStateInModalStateAnyUIProps | null
  ) => {
    const atomValue = getAtom();
    const exposuresStates = exposuresUpdated(localModalStateUI);
    const newStates = statesUpdatedWithExposures(
      atomValue,
      exposuresStates,
      localModalStateUI
    );
    const newStateList = getStateListUpdatedByExposures(newStates, atomValue);
    const statesWithExposures = getStatesUpdatedByExposures(
      newStates,
      localModalStateUI?.effectiveDate ?? new Date(),
      localModalStateUI?.expirationDate ?? new Date(),
      atomValue
    );
    const newAtomValue = updateQuoteInPolicyQuoteMultipleTargets(
      atomValue,
      ["states", "stateList"],
      [statesWithExposures, newStateList]
    );
    setAtom(newAtomValue);
    setStateCollectionUI({
      ...stateCollectionUI,
      states: statesWithExposures,
      stateCodeList: newStateList,
      isModalAnyOpen: false,
    });
  };

  const closeEvent = () =>
    setStateCollectionUI({
      ...stateCollectionUI,
      isModalOpen: false,
      isModalAnyOpen: false,
    });

  const collapseEvent = (
    expanded: boolean,
    stateCode: string,
    effectiveDate: Date,
    expirationDate: Date
  ) => {
    const atomValue = getAtom();

    const statesWithExpandedValueUpdated = getStatesUpdatedByExpanded(
      expanded,
      stateCode,
      effectiveDate,
      expirationDate,
      atomValue
    );
    const newAtomValue = updateQuoteInPolicyQuote(
      atomValue,
      "states",
      statesWithExpandedValueUpdated
    );

    const { allCollapsed, allExpanded } =
      getAllStatesCollapsedOrExpanded(newAtomValue);
    if (allCollapsed) {
      setAllStatesExpanded(false);
    }
    if (allExpanded) {
      setAllStatesExpanded(true);
    }

    setAtom(newAtomValue);
    setStateCollectionUI({
      ...stateCollectionUI,
      states: statesWithExpandedValueUpdated,
    });
    setPolicyQuoteTriggers(["exposureTableHeaderComponent"]);
  };

  const collapseAllEvent = () => {
    const atomValue = getAtom();
    setAllStatesExpanded(!allStatesExpanded);
    const statesUpdatedToBeCollapsed = getStatesUpdatedAsCollapsed(
      atomValue,
      !allStatesExpanded
    );
    const newAtomValue = updateQuoteInPolicyQuote(
      atomValue,
      "states",
      statesUpdatedToBeCollapsed
    );
    setAtom(newAtomValue);
    setStateCollectionUI({
      ...stateCollectionUI,
      states: statesUpdatedToBeCollapsed,
    });
    setPolicyQuoteTriggers(["exposureTableHeaderComponent"]);
  };

  return readyToRender ? (
    <div
      id="exposure-premium-main-container"
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      <div className="policy-scrollable-body-container">
        <Row {...rowWithNoMarginNorGutter} horizontalAlign="space-between">
          <Col
            breakpoints={{ md: 2, lg: 2, xl: 2 }}
            horizontalAlign="flex-start"
          >
            <SplitButton items={splitButtonOptions} disabled={readOnly} />
          </Col>
          <Col breakpoints={{ md: 2, lg: 2, xl: 2 }} horizontalAlign="flex-end">
            <Button
              id="id-collapse-all-states"
              name="expandCollapseAllStatesButton"
              variantStyle="outlined"
              size="medium"
              onClick={collapseAllEvent}
            >
              {`${allStatesExpanded ? "COLLAPSE" : "EXPAND"} ALL`}
            </Button>
          </Col>
        </Row>
        {stateCollectionUI.states?.map((state) => {
          return (
            <Box
              key={`container-collapse-${state?.stateCode}-${state?.effectiveDate}-${state?.expirationDate}`}
              id={`container-collapse-${state?.stateCode}-${state?.effectiveDate}-${state?.expirationDate}`}
              className="container-collapse-state"
              sx={{ ...collapseContainerStyles() }}
            >
              <Collapse
                id={`collapse-${state?.stateCode}`}
                name={`${castStringToTags(state?.stateName)}`}
                expanded={state.expanded ?? false}
                onChange={(expanded: boolean) =>
                  collapseEvent(
                    expanded,
                    state.stateCode ?? "",
                    state.effectiveDate ?? new Date(),
                    state.expirationDate ?? new Date()
                  )
                }
                title={
                  <ExposurePremiumStateHeader
                    tabKey={tabKey}
                    insuredId={insuredId}
                    stateName={state.stateName ?? ""}
                    stateCode={state.stateCode ?? ""}
                    effectiveDate={state.effectiveDate ?? new Date()}
                    expirationDate={state.expirationDate ?? new Date()}
                    readOnly={readOnly}
                    deleteState={deleteState}
                  />
                }
                content={
                  <PolicyCalculator
                    tabKey={tabKey}
                    insuredId={insuredId}
                    quoteStateId={state.quoteStateId}
                    stateCode={state.stateCode ?? ""}
                    stateName={state.stateName ?? ""}
                    effectiveDate={state.effectiveDate ?? new Date()}
                    expirationDate={state.expirationDate ?? new Date()}
                    policyEffectiveDate={effectiveDate}
                    readonly={readOnly}
                    policyFormType={PolicyFormTypeEnum.QUOTE_FORM}
                  />
                }
              />
            </Box>
          );
        })}
        {stateCollectionUI.states?.length ?? 0 > 0 ? (
          <ExposurePremiumFooter insuredId={insuredId} tabKey={tabKey} />
        ) : null}
      </div>
      <PolicyQuoteBottomButtons
        tabKey={tabKey}
        insuredId={insuredId}
        policySection={EXPOSURE_PREMIUM_SECTION}
        quoteStatus={quoteStatus}
        setActiveSection={setActiveSection}
      />
      {stateCollectionUI.initialStateValueWhenOpen && (
        <ModalState
          modalStatesUI={stateCollectionUI}
          saveEvent={saveNewState}
          closeEvent={closeEvent}
          policyEffectiveDate={effectiveDate}
          policyExpirationDate={expirationDate}
        />
      )}
      {stateCollectionUI.isModalAnyOpen &&
        stateCollectionUI.initialStateValueWhenOpen && (
          <ModalStateAny
            tabKey={tabKey}
            modalStatesUI={stateCollectionUI}
            saveEvent={saveNewStatesAny}
            closeEvent={closeEvent}
          />
        )}
    </div>
  ) : null;
};

export default ExposurePremiumMain;
