import { FC, useEffect, useState } from "react";
import { Col, Input, Row } from "../../../../../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../../../../../TrueUI/Grids/Row";
import { RowDiagnosisCodeProps } from "../TypesPaymentBillDetails";
import { useApiGet } from "../../../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../../../utilities/apiFunctions";
import { DiagnosisCodeDto } from "../../../../../../../../dtos/diagnosis-code-dto";
import DialogConfirmation from "../../../../../../../TrueUI/Dialogs/DialogConfirmation";

const RowDiagnosisCode: FC<RowDiagnosisCodeProps> = ({}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [diagnosisCode, setDiagnosisCode] =
    useState<Partial<DiagnosisCodeDto> | null>(null);
  const { dispatchGet, responseGet } = useApiGet<DiagnosisCodeDto>(
    `api/PaymentEOB/GetDiagnosisCodeByDiagnosisCodeValue?diagnosisCodeValue=${diagnosisCode?.code}`
  );

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      if (
        responseGet?.responseData?.diagnosisCodeId === 0 &&
        (diagnosisCode?.code?.length ?? 0) > 0
      ) {
        setShowAlert(true);
      }
      if ((responseGet?.responseData?.diagnosisCodeId ?? 0) !== 0) {
        setDiagnosisCode(responseGet?.responseData ?? null);
      }
    }
  }, [responseGet]);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter} numberOfColumns={24}>
        <Col
          breakpoints={{ xs: 4, sm: 4, md: 4, lg: 3, xl: 3 }}
          horizontalGutter={"15px"}
        >
          <Input
            id="paymentDiagnosisCodeCode"
            name="paymentDiagnosisCodeCode"
            value={diagnosisCode?.code ?? ""}
            onBlur={() => {
              dispatchGet();
            }}
            onChangeRawValue={(value) => {
              setDiagnosisCode({ ...diagnosisCode, code: value });
            }}
          />
        </Col>
        <Col
          breakpoints={{ xs: 3, sm: 3, md: 3, lg: 2, xl: 2 }}
          horizontalGutter={"10px"}
        >
          <Input
            id="paymentDiagnosisCodeType"
            name="paymentDiagnosisCodeType"
            value={diagnosisCode?.codeType ?? ""}
            readOnly
          />
        </Col>
        <Col
          breakpoints={{ xs: 17, sm: 17, md: 17, lg: 20, xl: 20 }}
          horizontalGutter={"10px"}
        >
          <Input
            id="paymentDiagnosisCodeDescription"
            name="paymentDiagnosisCodeDescription"
            value={diagnosisCode?.longDescription ?? ""}
            readOnly
          />
        </Col>
      </Row>
      <DialogConfirmation
        id="no-diagnosis-code-found"
        open={showAlert}
        dialogDescriptionText={
          "The code you entered could not be found.  You can leave it as-is or retry with a different code"
        }
        optionYesOverrideLabel={"OK"}
        onOptionYesEvent={(close) => {
          setShowAlert(close);
        }}
      />
    </>
  );
};

export default RowDiagnosisCode;
