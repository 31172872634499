import { FC, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import themes from "../../../../media/TrueTheme";
import { useApiGet } from "../../../../hooks";
import { GlobalInsuredAtomFamily } from "../../InsuredAtoms";
import { useAtomFamily } from "../../../../hooks/useAtomFamily";
import { INSURED_ATOM_KEY } from "../../../../utilities/queryStringsHash";
import {
  POLICY_DOCS_SECTION,
  PolicyQuoteInsuredAndActiveSetterProps,
} from "../PolicyQuoteForm/PolicyQuoteTypes";
import {
  getFilteredAndSortedPacketDocuments,
  getFilteredPolicyDocuments,
} from "./DocumentsUtils";
import DocumentFileOptions from "./DocumentFileOptions";
import DocumentSplitter from "./DocumentSplitter";
import PolicyQuoteBottomButtons from "../PolicyQuoteForm/PolicyQuoteBottomButtons";
import { removeValue } from "../../../../utilities/arrayFunctions";
import { FormattingDate } from "../../../../utilities/dateFunctions";
import { ProgramPacketDto } from "../../../../dtos/program-packet-dto";
import { ProgramPacketPage } from "../../../../dtos/program-packet-page";
import { ProgramPacketDocumentDto } from "../../../../dtos/program-packet-document-dto";
import { ProgramPolicyDocumentDto } from "../../../../dtos/program-policy-document-dto";
import { splitterStyles } from "./DocumentStyles";
import { QuotePolicySectionEnum } from "../../../../dtos/quote-policy-section-enum";
import { validatePreviousSections } from "../PolicyQuoteForm/PolicyQuoteValidationUtils";
import { updatePolicyQuoteInformation } from "../updatesPolicyQuoteFunctions";
import { PolicyFormTypeEnum } from "../../../../dtos/policy-form-type-enum";
import { getPolicyQuote } from "../PolicyQuoteForm/PolicyQuoteUtils";

const QUOTE_POLICY_API_PATH = "api/QuotePolicy";

type DocumentsMainUIProps = {
  selectedPackets: number[];
  selectedDocuments: number[];
  programPackets: ProgramPacketDto[];
  programDocuments: ProgramPacketDocumentDto[];
  policyDocuments: ProgramPolicyDocumentDto[];
  programPacketPageInformation: ProgramPacketPage | null;
};

const defaultDocumentsMainUI = {
  selectedDocuments: [],
  selectedPackets: [],
  programPackets: [],
  programDocuments: [],
  policyDocuments: [],
  programPacketPageInformation: null,
} as DocumentsMainUIProps;

const DocumentsMain: FC<PolicyQuoteInsuredAndActiveSetterProps> = ({
  insuredId,
  tabKey,
  quoteStatus,
  setActiveSection,
}) => {
  const insuredIdAtomKey = `${INSURED_ATOM_KEY} ${tabKey}`;
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(insuredIdAtomKey)
  );
  const localOptions = useRecoilValue(globalOptions);
  const theme = themes[localOptions?.themeRefresh];
  const [documentsMainUI, setDocumentsMainUI] = useState<DocumentsMainUIProps>(
    defaultDocumentsMainUI
  );
  const getPolicyId = () => {
    const atomValue = getAtom();
    return atomValue?.policyQuoteInformation?.policyId;
  };
  const getQuoteId = () => {
    const atomValue = getAtom();
    return atomValue?.policyQuoteInformation?.quoteId;
  };
  const getEffectiveDate = () => {
    const atomValue = getAtom();
    const policyQuote = getPolicyQuote(atomValue);
    return FormattingDate(policyQuote?.effectiveDate);
  };

  const { responseGet, dispatchGet } = useApiGet<ProgramPacketPage>(
    `${QUOTE_POLICY_API_PATH}/GetProgramPacketList?policyId=${getPolicyId()}&quoteId=${getQuoteId()}&effectiveDate=${getEffectiveDate()}&formType=${
      PolicyFormTypeEnum.QUOTE_FORM
    }`
  );

  const onSelectedCheckboxDocument = (
    checked: boolean,
    id: number,
    type: "packet" | "document"
  ) =>
    type === "packet"
      ? setDocumentsMainUI({
          ...documentsMainUI,
          selectedPackets: checked
            ? [...documentsMainUI.selectedPackets, id]
            : removeValue(documentsMainUI.selectedPackets, id),
        })
      : setDocumentsMainUI({
          ...documentsMainUI,
          selectedDocuments: checked
            ? [...documentsMainUI.selectedDocuments, id]
            : removeValue(documentsMainUI.selectedDocuments, id),
        });

  useEffect(() => {
    const atomValue = getAtom();
    const activeSection =
      atomValue?.policyQuoteInformation?.activeSection ??
      QuotePolicySectionEnum.INFORMATION;
    const isValid = validatePreviousSections(atomValue, activeSection);
    if (isValid) dispatchGet();
    else {
      const newAtomValue = updatePolicyQuoteInformation(
        atomValue,
        "activeSection",
        QuotePolicySectionEnum.INFORMATION
      );
      setAtom(newAtomValue);
      setActiveSection?.(QuotePolicySectionEnum.INFORMATION);
    }
  }, []);

  useEffect(() => {
    if (
      responseGet.requestInstanceSuccessful &&
      responseGet?.axiosResponse?.data
    ) {
      setDocumentsMainUI({
        ...documentsMainUI,
        programPacketPageInformation: responseGet.axiosResponse.data,
        programPackets: responseGet.axiosResponse.data.packets,
      });
    }
  }, [responseGet]);

  useEffect(() => {
    const filteredDocPackets = getFilteredAndSortedPacketDocuments(
      documentsMainUI.programPacketPageInformation?.packetDocuments,
      documentsMainUI.selectedPackets
    );
    const filteredDocPolicies = getFilteredPolicyDocuments(
      documentsMainUI.programPacketPageInformation?.policyDocuments,
      filteredDocPackets
    );

    setDocumentsMainUI({
      ...documentsMainUI,
      programDocuments: filteredDocPackets,
      policyDocuments: filteredDocPolicies,
    });
  }, [
    documentsMainUI.selectedPackets,
    documentsMainUI.programPacketPageInformation,
  ]);

  return (
    <div
      id="policy-documents-main-container"
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      <div className="policy-scrollable-body-container">
        <Box sx={splitterStyles(theme)}>
          <DocumentSplitter
            tabKey={tabKey}
            insuredId={insuredId}
            programPackets={documentsMainUI.programPackets}
            programDocuments={documentsMainUI.programDocuments}
            policyDocuments={documentsMainUI.policyDocuments}
            selectedPackets={documentsMainUI.selectedPackets}
            selectedDocuments={documentsMainUI.selectedDocuments}
            customJSONToPrint={
              documentsMainUI.programPacketPageInformation?.customPolicyJSON ??
              ""
            }
            onSelectedCheckboxDocument={onSelectedCheckboxDocument}
          />
        </Box>
      </div>
      <DocumentFileOptions
        tabKey={tabKey}
        insuredId={insuredId}
        selectedPackets={documentsMainUI.selectedPackets}
        selectedDocuments={documentsMainUI.selectedDocuments}
        programPackets={
          documentsMainUI.programPacketPageInformation?.packets ?? []
        }
        programDocuments={documentsMainUI.programDocuments}
        policyDocuments={documentsMainUI.policyDocuments}
        customJSONToPrint={
          documentsMainUI.programPacketPageInformation?.customPolicyJSON ?? ""
        }
      />
      <PolicyQuoteBottomButtons
        tabKey={tabKey}
        insuredId={insuredId}
        policySection={POLICY_DOCS_SECTION}
        quoteStatus={quoteStatus}
        setActiveSection={setActiveSection}
      />
    </div>
  );
};

export default DocumentsMain;
