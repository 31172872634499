import { FC } from "react";
import { Modal } from "../../../../../../TrueUI";
import { ModalPaymentBillDetailsWrapperProps } from "./TypesPaymentBillDetails";
import BillDetailsWrapper from "./BillDetailsWrapper";

const ModalPaymentBillDetailsWrapper: FC<
  ModalPaymentBillDetailsWrapperProps
> = ({ isModalOpen, setIsModalOpen }) => {
  return (
    <Modal
      id="payment-bill-details"
      title="Bill Details"
      open={isModalOpen}
      cancelEvent={() => setIsModalOpen(false)}
      closeEvent={() => setIsModalOpen(false)}
      saveEvent={() => {
        console.log("SAVE");
      }}
      size={"xl"}
    >
      <BillDetailsWrapper />
    </Modal>
  );
};
export default ModalPaymentBillDetailsWrapper;
