import { FC, useEffect, useState } from "react";
import { Button, Divider } from "../../../../../TrueUI";
import { InsuredInvoiceDetailsProps } from "./InsuredInvoiceDetailsProps";
import {
  useApiGet,
  usePermissions,
  useApiPost,
  useFormRequest,
} from "../../../../../../hooks";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../../../../TrueUI/Dialogs/DialogConfirmation";
import InsuredInvoiceInformationForm from "./InsuredInvoiceInformationForm";
import InvoiceDetailTable from "./InvoiceDetailTable";
import { InsuredInvoiceModalOptionsDto } from "../../../../../../dtos/insured-invoice-modal-options-dto";
import { InsuredInvoiceDto } from "../../../../../../dtos/insured-invoice-dto";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../../../utilities/apiFunctions";
import { InvoiceJSON } from "../../../../../../dtos/invoice-json";
import { isEmptyValue } from "../../../../../../utilities/conditionalSupportFunctions";
import { FormTypeEnum } from "../../../../../../dtos/form-type-enum";
import styles from "./InsuredInvoiceDetailsStyles.module.css";
import { PermissionsEnums } from "../../../../../../dtos/permissions-enums";
import { INSURED_ATOM_KEY } from "../../../../../../utilities/queryStringsHash";
import { useAtomFamily } from "../../../../../../hooks/useAtomFamily";
import { GlobalInsuredAtomFamily } from "../../../../InsuredAtoms";
import { InvoiceItemModalConfigProps } from "../InsuredInvoiceItem/TypesForModalAddInvoiceItem";
import ModalAddInvoiceItem from "../InsuredInvoiceItem/ModalInvoiceItem";

const billingPermissions = [
  PermissionsEnums.BILLING_PAYMENT,
  PermissionsEnums.BILLING_USER,
  PermissionsEnums.BILLING_ADMIN,
];

const ModalInsuredInvoiceDetails: FC<InsuredInvoiceDetailsProps> = ({
  invoiceDetailsProps,
  setInvoiceDetailsProps,
}) => {
  const { getAtom, setAtom } = useAtomFamily(
    GlobalInsuredAtomFamily(
      `${INSURED_ATOM_KEY} ${invoiceDetailsProps?.tabKey}`
    )
  );

  const insuredAtomFamilyValues = getAtom();
  const hasPermissionToEdit = usePermissions(billingPermissions).hasPermission;

  const [invoiceItemModalConfig, setInvoiceItemModalConfig] =
    useState<InvoiceItemModalConfigProps>({
      showItemModal: false,
      insuredId: invoiceDetailsProps?.insuredId,
      invoiceId: invoiceDetailsProps?.invoiceId,
      invoiceSubId: null,
      invoiceDetailId: null,
      maxLineNumber: 1,
      refreshDetailsTable: false,
    });

  const [formData, setFormData] = useState<Partial<InsuredInvoiceDto> | null>({
    invoiceId: 0,
    insuredId: invoiceDetailsProps.insuredId,
    actionPerformed: invoiceDetailsProps?.renderingOption ?? "",
  });
  const { sendMergeFormRequest } = useFormRequest();
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);

  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost<
    number | null
  >("api/Invoice/SaveInvoiceInformation", formData);
  const {
    responsePost: validationResponse,
    dispatchPost: dispatchValidation,
    validatorErrorResponse: validationErrorResponse,
  } = useApiPost<number | null>(
    "api/Invoice/ValidateInvoiceInformationForAddingItem",
    { ...formData, invoiceNumber: "validation" }
  );

  const {
    responsePost: responseCreateBillingTransactions,
    dispatchPost: dispatchCreateBillingTransactions,
  } = useApiPost<number | null>(
    "api/Invoice/CreateBillingTransactionsForNewInvoices",
    formData
  );

  const {
    responsePost: responseDeleteInvoice,
    dispatchPost: dispatchDeleteInvoice,
  } = useApiPost("api/Invoice/DeleteInvoiceByInvoiceId", formData);
  const {
    responseGet: responseGetModalSelectOptions,
    dispatchGet: dispatchGetModalSelectOptions,
  } = useApiGet<InsuredInvoiceModalOptionsDto>(
    `api/Invoice/GetInsuredInvoiceModalSelectOptions`
  );
  const {
    responseGet: responseGetInvoiceJSON,
    dispatchGet: dispatchGetInvoiceJSON,
  } = useApiGet<InvoiceJSON>(
    `api/Invoice/GetInvoiceJSON?invoiceId=${invoiceDetailsProps.invoiceId}`
  );

  const printInvoice = (invoiceJSON?: InvoiceJSON) => {
    if (
      invoiceJSON !== undefined &&
      invoiceJSON !== null &&
      !isEmptyValue(invoiceJSON.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.INVOICE_FORM,
        invoiceMergeFields: invoiceJSON,
        templateNameWithExtensionList: [invoiceJSON.templateName ?? ""],
        customFileName: `${invoiceJSON.insuredName} Invoice`,
      });
    }
    if (isEmptyValue(invoiceJSON?.templateName)) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "No print template found.",
        optionYesOverrideLabel: "OK",
        onOptionYesEvent: () => setDialogConfiguration(null),
      });
    }
  };

  useEffect(() => {
    if (isAPITotallyComplete(responseCreateBillingTransactions)) {
      setInvoiceDetailsProps({
        ...invoiceDetailsProps,
        renderingOption: "table",
        invoiceId: 0,
      });
      setDialogConfiguration(null);
    }
  }, [responseCreateBillingTransactions]);

  const cancelEvent = () =>
    setDialogConfiguration({
      open: true,
      dialogDescriptionText:
        "Are you sure you want to cancel, your work will be lost?",
      onOptionNoEvent: () => setDialogConfiguration(null),
      onOptionYesEvent: () => {
        if (
          invoiceDetailsProps.renderingOption === "add-view" &&
          invoiceDetailsProps?.invoiceId !== 0
        ) {
          dispatchCreateBillingTransactions();
        } else {
          setInvoiceDetailsProps({
            ...invoiceDetailsProps,
            renderingOption: "table",
            invoiceId: 0,
          });
          setDialogConfiguration(null);
        }
      },
    });

  useEffect(() => {
    if ((formData?.invoiceItems?.length ?? 0) === 1) {
      dispatchPost();
    }
  }, [formData?.invoiceItems]);

  useEffect(() => {
    dispatchGetModalSelectOptions();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      if (formData?.invoiceId === 0) {
        setInvoiceItemModalConfig({
          ...invoiceItemModalConfig,
          invoiceId: responsePost?.responseData ?? 0,
          showItemModal: false,
          refreshDetailsTable: true,
        });
        setInvoiceDetailsProps({
          ...invoiceDetailsProps,
          invoiceId: responsePost?.responseData ?? 0,
        });
        setFormData({
          ...formData,
          invoiceItemBackUpForError: null,
          invoiceId: responsePost?.responseData ?? 0,
          invoiceItems: [],
        });
      }
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseDeleteInvoice)) {
      setInvoiceDetailsProps({
        ...invoiceDetailsProps,
        renderingOption: "table",
      });
    }
  }, [responseDeleteInvoice]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetInvoiceJSON))
      printInvoice(responseGetInvoiceJSON.axiosResponse?.data);
  }, [responseGetInvoiceJSON]);

  const saveInvoice = () => {
    if (invoiceDetailsProps.formDataChanged === true) {
      setInvoiceDetailsProps({
        ...invoiceDetailsProps,
        formDataChanged: false,
      });
      dispatchPost();
    }
    if (
      invoiceDetailsProps.formDataChanged === false &&
      invoiceDetailsProps.renderingOption !== "add-view"
    ) {
      setInvoiceDetailsProps({
        ...invoiceDetailsProps,
        renderingOption: "table",
      });
      dispatchPost();
    }
    if (invoiceDetailsProps.renderingOption === "add-view") {
      dispatchPost();
    }
  };

  const deleteValidation = () => {
    if (formData?.hasBillingTransactions === true) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          "This invoice has already been released and is present in the billing activity.  If you wish to delete this invoice, first clear or edit the billing activity and then come back and delete it.",
        onOptionYesEvent: () => setDialogConfiguration(null),
        optionYesOverrideLabel: "Ok",
      });
    }
    if (formData?.hasBillingTransactions === false) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText: "Are you sure you want to delete this invoice?",
        onOptionYesEvent: () => dispatchDeleteInvoice(),
        onOptionNoEvent: () => setDialogConfiguration(null),
      });
    }
  };
  const setInvoiceSubIdAndDetailId = (
    invoiceSubId: number,
    invoiceDetailId: number,
    maxLineNumber: number
  ) => {
    setInvoiceItemModalConfig({
      ...invoiceItemModalConfig,
      invoiceSubId: invoiceSubId !== 0 ? invoiceSubId : null,
      showItemModal: true,
      invoiceDetailId: invoiceDetailId !== 0 ? invoiceDetailId : null,
      maxLineNumber: maxLineNumber,
    });
  };

  useEffect(() => {
    if (
      (formData?.invoiceItems?.length ?? 0) > 0 &&
      (validationErrorResponse?.errorDetails?.description ?? null) !== null &&
      (validationErrorResponse?.errorDetails?.tranAccountId ?? null) !== null
    ) {
      setInvoiceItemModalConfig({
        ...invoiceItemModalConfig,
        showItemModal: false,
        maxLineNumber: invoiceItemModalConfig?.maxLineNumber ?? 0 + 1,
      });
    }
  }, [formData?.invoiceItems]);

  useEffect(() => {
    if ((formData?.policyId ?? 0) !== 0) {
      setAtom({
        ...insuredAtomFamilyValues,
        billingPolicyFilterSelected: {
          policyId:
            formData?.policyId !==
            insuredAtomFamilyValues?.billingPolicyFilterSelected?.policyId
              ? formData?.policyId?.toString() ?? ""
              : "",
        },
      });
    }
  }, [formData?.policyId]);

  useEffect(() => {
    setFormData({
      ...formData,
      actionPerformed: invoiceDetailsProps?.renderingOption ?? null,
      invoiceItemBackUpForError: null,
    });
  }, [invoiceDetailsProps?.renderingOption]);

  useEffect(() => {
    if (validatorErrorResponse && formData?.invoiceItems?.length === 1) {
      setFormData({
        ...formData,
        invoiceItemBackUpForError: formData?.invoiceItems?.[0],
      });
    }
  }, [validatorErrorResponse]);

  useEffect(() => {
    if (
      isAPITotallyCompleteNoContentResponse(validationResponse) &&
      validationErrorResponse === null
    ) {
      setInvoiceItemModalConfig({
        ...invoiceItemModalConfig,
        showItemModal: true,
        invoiceSubId: null,
        invoiceDetailId: null,
      });
    }
  }, [validationResponse]);

  useEffect(() => {
    if (validationErrorResponse !== null) {
      setDialogConfiguration({
        open: true,
        dialogDescriptionText:
          "An invoice item can't be created with missing required fields for the invoice",
        onOptionYesEvent: () => setDialogConfiguration(null),
        optionYesOverrideLabel: "OK",
      });
    }
  }, [validationErrorResponse]);

  const addInvoiceItem = () => {
    if ((formData?.invoiceId ?? 0) === 0) {
      dispatchValidation();
    } else {
      setInvoiceItemModalConfig({
        ...invoiceItemModalConfig,
        showItemModal: true,
        invoiceSubId: null,
        invoiceDetailId: null,
      });
    }
  };

  return (
    <>
      <div className={styles.invoice_tab_content}>
        <InsuredInvoiceInformationForm
          policyIdFromAtom={
            insuredAtomFamilyValues?.billingPolicyFilterSelected?.policyId ??
            null
          }
          insuredId={invoiceDetailsProps.insuredId}
          invoiceTypes={
            responseGetModalSelectOptions?.axiosResponse?.data.invoiceTypes ??
            []
          }
          statuses={
            responseGetModalSelectOptions?.axiosResponse?.data.statuses ?? []
          }
          invoiceId={invoiceDetailsProps?.invoiceId}
          hasPermissionToEdit={hasPermissionToEdit}
          formData={formData}
          setFormData={setFormData}
          setInvoiceDetailsProps={setInvoiceDetailsProps}
          invoiceDetailsProps={invoiceDetailsProps}
          errorDetails={
            validatorErrorResponse?.errorDetails ??
            validationErrorResponse?.errorDetails ??
            {}
          }
        />
        <Divider height={15} />
        <InvoiceDetailTable
          invoiceId={invoiceDetailsProps?.invoiceId}
          setInvoiceSubIdAndDetailId={setInvoiceSubIdAndDetailId}
          refreshTable={invoiceItemModalConfig.refreshDetailsTable}
          resetRefreshTable={() =>
            setInvoiceItemModalConfig({
              ...invoiceItemModalConfig,
              refreshDetailsTable: false,
            })
          }
        />
        <Divider height={30} />

        <div className={styles.edit_invoice_actions_container}>
          <div className={styles.edit_invoice_left_actions}>
            {invoiceDetailsProps.invoiceId !== 0 && (
              <>
                <Button
                  variantStyle={"outlined"}
                  onClick={() => {
                    deleteValidation();
                  }}
                >
                  DELETE
                </Button>

                <Button
                  variantStyle={"outlined"}
                  onClick={() => dispatchGetInvoiceJSON()}
                >
                  PRINT
                </Button>
              </>
            )}
            <Button variantStyle={"outlined"} onClick={() => addInvoiceItem()}>
              ADD ITEM
            </Button>
          </div>
          <div className={styles.edit_invoice_right_actions}>
            <Button
              permissions={billingPermissions}
              onClick={() => saveInvoice()}
            >
              SAVE
            </Button>
            <Button variantStyle={"outlined"} onClick={cancelEvent}>
              CANCEL
            </Button>
          </div>
        </div>

        <Divider height={20} />
      </div>
      <DialogConfirmation
        id="insuredInvoiceDialogConfirmation"
        {...dialogConfiguration}
        onCloseEvent={() => {
          setDialogConfiguration(null);
        }}
      />
      <ModalAddInvoiceItem
        invoiceItemModalConfig={invoiceItemModalConfig}
        setInvoiceItemModalConfig={setInvoiceItemModalConfig}
        invoiceData={formData}
        setInvoiceData={setFormData}
        errorFromCreatingInvoice={validatorErrorResponse?.errorDetails ?? null}
      />
    </>
  );
};

export default ModalInsuredInvoiceDetails;
