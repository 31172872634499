import { FC, useEffect, useState } from "react";
import { GroupSectionIcon } from "../../../../../../TrueUI/Icons/CustomIcons";
import {
  ButtonPaymentBillDetailsProps,
  ButtonBillingDetailsConfigProps,
} from "./TypesPaymentBillDetails";
import { IconButton } from "@mui/material";
import style from "./PaymentBillDetails.module.css";
import { useApiGet, usePermissions } from "../../../../../../../hooks";
import { isAPITotallyComplete } from "../../../../../../../utilities/apiFunctions";
import ModalPaymentBillDetailsWrapper from "./ModalPaymentBillDetailsWrapper";
import {
  addPaymentPermissions,
  GetShouldDisablePaymentBillDetailsButton,
  GetShouldDisplayPaymentBillDetailsButton,
} from "./UtilsPaymentBillDetails";

const ButtonPaymentBillDetails: FC<ButtonPaymentBillDetailsProps> = ({
  paymentData,
}) => {
  const { hasPermission } = usePermissions(addPaymentPermissions);
  const [
    shouldDisplayPaymentBillDetailsButton,
    setShouldDisplayPaymentBillDetailsButton,
  ] = useState(false);

  const [disablePaymentBillDetailsButton, setDisablePaymentBillDetailsButton] =
    useState(true);

  const [buttonBillingDetailsConfig, setButtonBillingDetailsConfig] =
    useState<ButtonBillingDetailsConfigProps>({
      programClaimEOBEntry: false,
      permissionToAddPayment: hasPermission,
      openModal: false,
    });

  const { responseGet, dispatchGet } = useApiGet<any>(
    "api/Program/GetCurrentProgram"
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setButtonBillingDetailsConfig({
        ...buttonBillingDetailsConfig,
        programClaimEOBEntry:
          responseGet?.axiosResponse?.data?.claimEOBEntry ?? false,
      });
    }
  }, [responseGet]);

  useEffect(() => {
    const display = GetShouldDisplayPaymentBillDetailsButton(
      paymentData,
      buttonBillingDetailsConfig
    );
    const disable = GetShouldDisablePaymentBillDetailsButton(paymentData);
    setShouldDisplayPaymentBillDetailsButton(display);
    setDisablePaymentBillDetailsButton(disable);
  }, [paymentData]);

  useEffect(() => {
    const display = GetShouldDisplayPaymentBillDetailsButton(
      paymentData,
      buttonBillingDetailsConfig
    );
    setShouldDisplayPaymentBillDetailsButton(display);
  }, [
    buttonBillingDetailsConfig?.programClaimEOBEntry,
    buttonBillingDetailsConfig?.permissionToAddPayment,
  ]);

  const updateModalDisplay = (isModalOpen: boolean) => {
    setButtonBillingDetailsConfig({
      ...buttonBillingDetailsConfig,
      openModal: isModalOpen ?? false,
    });
  };

  return (
    <>
      {shouldDisplayPaymentBillDetailsButton && (
        <IconButton
          className={style.payment_bill_detail_icon_button}
          onClick={() => {
            console.log({ paymentData });
            setButtonBillingDetailsConfig({
              ...buttonBillingDetailsConfig,
              openModal: true,
            });
          }}
          disabled={disablePaymentBillDetailsButton}
        >
          <GroupSectionIcon />
        </IconButton>
      )}
      <ModalPaymentBillDetailsWrapper
        isModalOpen={buttonBillingDetailsConfig?.openModal ?? false}
        setIsModalOpen={updateModalDisplay}
      />
    </>
  );
};

export default ButtonPaymentBillDetails;
